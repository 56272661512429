import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'

import { Marqy } from 'marqy'
import 'marqy/styles.css'

import { Image } from 'src/components/image'
import { Serializer } from 'src/utils/serializer'

export interface DeckProps {
  data: {
    deckDescription: string
    image: string
    marqueeText: any[]
    title: string
    productReference: {
      chef?: {}
      description: any[]
      slug: string
      linkedProducts?: any[]
    }
  }
}

export const Deck = ({ data }: DeckProps) => {
  const {
    deckDescription,
    image,
    marqueeText,
    title,
    productReference: {
      chef,
      slug,
      description,
      linkedProducts
    }
  } = data
  return (
    <div className='mt4 deck deck-col mb4 x p2--800'>
      <div className='container--1300 mxa outer p2--800'>
        <div className='ba--800 bc bt'>
          {marqueeText && (
            <div className='bg--theme-btn marquee__text of--hidden df jcc aic bb bc pr'>
              <Marqy speed='0.2'>
                {marqueeText.map((t, i) => (
                  <span className='ml4 mr4 s14 color--white courier caps' key={i}>{t}</span>
                ))}
              </Marqy>
            </div>
          )}
          <div className='row gutter--none df jcb fw'>
            <div className='col c14 pr deck__image c9--1000 br--1000 bc'>
              <div className='p4--1000 y deck__image-wrapper'>
                <Image className='top x y p4--1000 left' imageId={image.imageId} />
              </div>
            </div>
            <div className='col c14 c5--1000 deck__information'>
              <div className='df fdc jcb y'>
                <div>
                  <div className='p2 p4--1000'>
                    {linkedProducts && (
                      <div className='product__card-avatar ba bc s12 medium  mr1 mb2 caps dib mb '>
                        <span className='medium dib'>{linkedProducts.length} {linkedProducts[0].productType} cards</span>
                      </div>
                    )}
                    <h2>{title}</h2>
                  </div>
                </div>
                <div className='bt bc'>
                  <div className='p2 p4--1000'>
                    <p>{deckDescription}</p>
                    {chef && (
                      <div className='df mt3 row aic ba gutter--none'>
                        <div className='col c3 br pr y deck__chef-image'>
                          <Image className='pa x y object--fit' imageId={chef.image.imageId} alt={chef.altText} />
                        </div>
                        <div className='col tc c11'>
                          <div className='p1'>
                            <span className='s14 courier'>By Chef {chef.name}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <Link to={`/products/${slug}`} className='button db x'>
                    <span>Shop This Card Deck</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Deck